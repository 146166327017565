@import '~ethos-design-system/src/components/Media/Media.scss';
@import '~ethos-design-system/src/components/Colors.scss';

.componentWrapper {
  @include for-laptop-only {
    padding-bottom: 18px;
  }
  position: relative;
  background-color: var(--BrandForest);
  z-index: 0;
  overflow: hidden;
}

.contentWrapper {
  @include for-phone-only {
    margin-top: 0;
    margin-bottom: var(--Space-48);
  }
  @include for-tablet-only {
    margin-bottom: var(--Space-64);
    margin-top: var(--Space-40);
  }
  @include for-laptop-only {
    margin-bottom: var(--Space-24);
  }
  margin-top: 50px;
  position: relative;
  z-index: 2;
}

.layoutContainer {
  @include for-phone-only {
    padding: 0;
  }
  @include for-tablet-only {
    padding: 0;
  }
  @include for-laptop-only {
    padding-left: 0;
    padding-right: 30px;
  }
  padding-left: var(--Space-40);
  position: relative;
  z-index: 3;
}

.container {
  @include for-phone-only {
    flex-direction: column-reverse;
    align-items: center;
  }
  @include for-tablet-only {
    flex-direction: column-reverse;
    align-items: center;
  }
  display: flex;
  flex-direction: row;
}

.imgContainer {
  @include for-phone-only {
    width: 320px;
  }
  @include for-tablet-only {
    width: 600px;
  }
  width: 62%;
}

.mainImgWrapper {
  display: flex;
  justify-content: center;
  width: 100%;
}

.logoImgWrapper {
  @include for-phone-only {
    width: 401px;
    bottom: var(--Space-32);
    left: -50.125px;
  }
  @include for-tablet-only {
    width: 793px;
    bottom: var(--Space-48);
    left: -99.125px;
  }
  @include for-laptop-only {
    bottom: var(--Space-40);
  }
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  bottom: var(--Space-80);
}

.contentContainer {
  @include for-phone-only {
    margin-top: var(--Space-56);
    padding: 0 var(--Space-24);
    width: 100%;
  }
  @include for-tablet-only {
    padding: 0 var(--Space-32);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  @include for-laptop-only {
    margin: var(--Space-8) 0 0 var(--Space-12);
  }
  margin: var(--Space-72) 0 0 var(--Space-12);
  width: 38%;
}

.headingWrapper {
  @include for-phone-only {
    width: 71.875%;
  }
  @include for-tablet-only {
    margin-bottom: var(--Space-32);
    width: 490px;
  }
  @include for-laptop-only {
    margin-bottom: var(--Space-40);
    width: 300px;
  }
  margin-bottom: var(--Space-24);
  width: 426px;
  font-size: 19px;
  line-height: 24px;
  letter-spacing: -0.0075em;
}

.subheadingWrapper {
  @include for-phone-only {
    width: 85.3125%;
    margin-bottom: var(--Space-48);
  }
  @include for-tablet-only {
    width: 460px;
    margin-bottom: var(--Space-64);
  }
  @include for-laptop-only {
    width: 300px;
  }
  width: 452px;
}

.background {
  @media (min-width: 320px) and (max-width: 429px) {
    right: 141vw;
    bottom: 7px;
  }
  @media (min-width: 420px) and (max-width: 599px) {
    right: 67vw;
  }
  @media (min-width: 600px) and (max-width: 719px) {
    right: 138vw;
  }
  @media (min-width: 720px) and (max-width: 899px) {
    right: 95vw;
  }
  @media (min-width: 900px) and (max-width: 999px) {
    right: 50vw;
  }
  @media (min-width: 1000px) and (max-width: 1200px) {
    right: 40vw;
  }
  @media (min-width: 1700px) {
    right: 0px;
  }
  position: absolute;
  width: 100%;
  right: 33vw;
  bottom: 0;
  z-index: 1;

  svg {
    @include for-phone-only {
      width: 1200px;
      height: 798px;
    }
    @media (min-width: 1700px) {
      width: 100%;
      height: auto;
    }
    width: 2300px;
    height: 1530px;
  }
}
